#navbar {
  display: block;
  position: sticky;
  top: 0;
  z-index:10;
  justify-content: center;
  margin-bottom: 0px;
}

#promo {
  background-color: black;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#promoMatrix {
  background-image: url('./200w.gif');
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#promo::-webkit-scrollbar {
  display: none;
}

#lblCartCount {
  font-size: 10px;
  background: white;
  color: black;
  padding: 3 3px;
  vertical-align: top;
  margin-left: -5px;
}

.App {
  text-align: center;
}

.App-header {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Dela Gothic One', cursive;
  font-weight: 400;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-display: swap;
}

.App-link {
  color: #61dafb;
}

.logo {
	transform: scale(1);
	animation: pulse 2s infinite;
}

.addConfirmation {
  transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1.0);
	}

	100% {
		transform: scale(0.95);
	}
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.invis {
  background: transparent;
  border: none !important;
}

.uploadForm {
  width: 80vh;
  text-align: left;
}

.navIcons {
  margin-left: 6px !important;
  margin-right: 6px !important;
  color: #9B9D9E !important;
}

.noBorder:checked+.noBorder, .noBorder.active, .noBorder.show, .noBorder:first-child:active, :not(.noBorder-check)+.noBorder:active{
  border: 0px !important;
}

.circle {
  background-color: white;
  text-align: center;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  width:70px;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.forms:focus{
  border-color: black !important;
  box-shadow: 0 0 0 0.1rem transparent !important;
}

.noHover:hover{
  background-color: transparent !important;
  background: transparent !important;
}

.qty {
  color: rgba(var(--color-foreground));
    position: relative;
    width: calc(14rem / var(--font-body-scale) + var(--inputs-border-width) * 2);
    display: flex;
    border-radius: var(--inputs-radius);
    min-height: calc((var(--inputs-border-width) * 2) + 4.5rem);
}

.qtyInput {
  color: currentColor;
  font-size: 1.4rem;
  font-weight: 500;
  opacity: .85;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0 0.5rem;
  width: 50%;
  flex-grow: 1;
  -webkit-appearance: none;
  appearance: none;
}

.qtyButton {
  width: 17px;
  flex-shrink: 0;
  font-size: 1.8rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--color-foreground));
  padding: 0;
}

.buttonGlow {
  display: inline-flex !important;
  float: right !important;
  margin-right: 5px !important;
  background-color: black !important;
  color: white !important;
  border-color: black !important;
}

.buttonBorder {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}


@media only screen and (min-width: 1038px) {
  .card {
    padding: 0 !important;
    transition: transform .2s; /* Animation */
  }

  .card:hover {
    transform: scale(1.05);
  }

  .categoryButtons:hover {
    transform: scale(1.05);
  }

  .navbarButtons:hover {
    transform: scale(1.25);
  }

  .cardTotal {
    margin-bottom: 4rem;
    max-width: 300px !important;
    width: 30vw !important;
    height: 400px !important;
  }

  .cardFeatured {
    color: 'black';
    text-align: 'left';
    padding: '0';
    font-size: smaller !important;
  }

  .cardImages {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding-bottom: 3px;
  }

  .cardBody {
    height: 50px;
    padding: 0 !important;
  }

  .cardName {
    text-align: left;
    color: black;
    margin: 0;
    padding: 0;
    border: 0;
    font-style: italic;
  }

  .cardPrice {
    text-align: right;
    color: black;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: medium !important;
  }

  #productPage {
    height: 60vh;
    width: 100vh;
  }

  #imageAndShipping {
    width: 50%;
    float: left;
  }
  #productImage {
    max-height: 50vh;
    width: 50vh;
    object-fit: scale-down;
  }

  #productText {
    float: right;
    width: 400px;
    border-left: 50px;
    font-size: medium;
  }

  #shippingInfo {
    font-size: small;
  }

  .carouselImage {
    background-color: rgba(128, 128, 128, 0.385);
    height: 60vh;
    width: 60vh;
    object-fit: contain;
  }
}

@media only screen and (max-width: 1037px) {
  #productPage {
    text-align: left;
    width: 480px;
  }
  .carouselImage {
    background-color: rgba(128, 128, 128, 0.385);
    height: 70vw;
    width: 70vw;
    /* justify-content: left; */
    object-fit: contain;
    padding-bottom: 3px;
  }
  .cardTotal {
    width: 40vw !important;
    height: 100% !important;
  }
  .cardBody {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important
  }
  .cardImages {
    left: 0px;
    height: 40vw !important;
    width: 40vw !important;
    object-fit: cover;
    padding-bottom: 3px;
  }
}

@media only screen and (max-width: 720px) {
  #productPage {
    text-align: left;
    width: 480px;
  }
  .carouselImage {
    background-color: rgba(128, 128, 128, 0.385);
    height: 90vw;
    width: 90vw;
    /* justify-content: left; */
    object-fit: contain;
    padding-bottom: 3px;
  }
  .cardTotal {
    width: 40vw !important;
    height: 100% !important;
  }
  .cardBody {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important
  }
  .cardImages {
    left: 0px;
    height: 40vw !important;
    width: 40vw !important;
    object-fit: cover;
    padding-bottom: 3px;
  }
}

@media only screen and (max-width: 414px) {
  .cardTotal {
    width: 40vw !important;
    height: 100% !important;
    /* height: 200px !important; */
  }
  .cardBody {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important
  }
  .cardImages {
    left: 0px;
    height: 30vw !important;
    width: 30vw !important;
    object-fit: cover;
    padding-bottom: 3px;
  }
  .carouselImage {
    background-color: rgba(128, 128, 128, 0.385);
    height: 90vw;
    width: 90vw;
    /* justify-content: left; */
    object-fit: contain;
    padding-bottom: 3px;
  }
  #colorOptions {
    width: 100% !important
  }
  .navIcons {
    margin-left: 5px !important;
    margin-right: 5px !important;
    color: #9B9D9E !important;
  }
}

@media only screen and (max-width: 321px) {
  .cardName {
    font-size: smaller !important;
  }
  .cardPrice {
    font-size: smaller !important
}
  .navIcons {
    margin-left: 0px !important;
    margin-right: 0px !important;
    color: #9B9D9E !important;
  }
}

@media only screen and (max-width: 319px) {
  .cardTotal {
    width: 85vw !important;
    height: 100% !important;
    /* height: 200px !important; */
  }
  .cardBody {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important
  }
  .cardImages {
    left: 0px;
    height: 75vw !important;
    width: 75vw !important;
    object-fit: cover;
    padding-bottom: 3px;
  }
}